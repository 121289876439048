<template>
  <div>
    <vs-tabs class="invoice-list">
      <vs-tab label="Nurse Details">
        <div>
          <nurse-detail
            fetchClinicsBy="superAdmin"
            :canDelete="true"
            :inTabEdit="true"
            :adminApprovalVar="true"
          />
        </div>
      </vs-tab>
      <vs-tab label="Organizations">
        <associated-organization-table :userId="nurseId" />
      </vs-tab>
      <vs-tab label="Clinics">
          <div v-if="check_has_permission('viewNurseAssociatedClinics')">
            <associated-clinics
              :associatedIds="
                nurseData.associatedClinicIds.map((e) => e.clinicId)
              "
              :inTabEdit="true"
              :userId="this.$route.params.nurseId"
              userTypeToEdit="nurse"
            />
          </div>
      </vs-tab>
      <!-- <vs-tab label="Treatments">
        <vs-card v-if="check_has_permission('viewNurseTreatmentHistory')">
          <template v-slot:header>
            <h4>Nurse Treatment History</h4>
          </template>
          <NurseTreatmentHistory
            :nurseDetails="nurseData"
          ></NurseTreatmentHistory>
        </vs-card>
      </vs-tab> -->
      <vs-tab label="Permissions" name="permissions">
          <PermissionSettings
            userType="nurse"
            :userId="nurseId"
          />
      </vs-tab>
    </vs-tabs>
  </div>
</template>

<script>
import nurseDetail from "../../components/nurses/nurseDetail.vue";
import AssociatedClinics from "../../components/others/associatedClinics.vue";
import NurseTreatmentHistory from "../../components/nurses/NurseTreatmentHistory.vue";
import AssociatedOrganizationTable from "../../components/organization/associatedOrganizationTable.vue";
import PermissionSettings from "../../components/roles-permissions/PermissionSettings.vue";
import { mapActions } from "vuex";

export default {
  components: {
    nurseDetail,
    AssociatedClinics,
    NurseTreatmentHistory,
    AssociatedOrganizationTable,
    PermissionSettings,
  },
  data: () => ({
    nurseData: {
      _id: null,
      firstName: "",
      lastName: "",
      email: "",
      associatedClinicIds: [],
    },
    nurseId:null
  }),
  methods: {
    ...mapActions("nurse", ["fetchNurseDetails"]),
    async getNurseDetail(nurseId) {
      await this.fetchNurseDetails(nurseId).then((res) => {
        const nurse = res.data.data;
        this.nurseData = {
          _id: nurseId,
          firstName: nurse.firstName,
          lastName: nurse.lastName,
          associatedClinicIds: nurse.associatedClinicIds,
        };
      });
    },
    formatDate(date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();
      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;
      return [year, month, day].join("-");
    },
  },
  async created() {
    this.nurseId = this.$route.params.nurseId;
    await this.getNurseDetail(this.nurseId);
  },
};
</script>
